/**
 * Google Tag manager setting for sending events to Google Analytics.
 * https://developers.google.com/tag-manager/quickstart
 */

window.dataLayer = window.dataLayer || [];

const TrackGAEvents = (trackingObject) => window.dataLayer.push(trackingObject);

export default TrackGAEvents;
