import getEnvironment from 'config/getEnvironment';
import TrackGAEvents from 'lib/ga-tracker';
import { TransactionProduct } from 'types/session-details';
import { ClickCTA, PurchaseError, ViewContent } from './tracking-constants';

export const PRODUCT_ACRONYM = Object.freeze({
  'GoTo Resolve': 'g2rv',
  GoToConnect: 'g2c',
  Pro: 'lmip',
  Central: 'lmic',
  Rescue: 'lmir',
  G2M: 'g2m',
  G2W: 'g2w',
  G2T: 'g2t',
});

export const PLAN_TYPE = Object.freeze({
  PAID_MONTHLY: 'monthly',
  PAID_ANNUAL: 'yearly',
});

const getEnvironmentTag = () => {
  const env = getEnvironment();
  switch (env) {
    case 'production':
      return 'op-prod';
    case 'staging':
      return 'op-stage';
    case 'rc':
      return 'op-rc';
    case 'development':
      return 'op-dev';
    default:
      return 'op-local';
  }
};

const getProductString = (products: TransactionProduct[]) => {
  return products.map((product) => PRODUCT_ACRONYM[product.productGroup] || product.productGroup).join(',');
};

const getPlanType = (products: TransactionProduct[]) => {
  return products.map((product) => PLAN_TYPE[product.planTermType] || product.planTermType).join(',');
};

export const trackViewContent = ({ country, language, products, locale, referrerURL }) =>
  TrackGAEvents({
    event: ViewContent,
    platform_country: country,
    platform_environment: getEnvironmentTag(),
    platform_language: language,
    platform_locale: locale,
    platform_product: getProductString(products),
    platform_type: 'marketing',
    purchase_source: referrerURL,
  });

export const trackClickCTA = ({ ctaName }) =>
  TrackGAEvents({
    event: ClickCTA,
    cta_component: 'OnePay',
    cta_name: ctaName,
    cta_url: '',
  });

export const trakcErrorPurchase = ({ products, paymentMethod, errMsg }) =>
  TrackGAEvents({
    event: PurchaseError,
    plan: getPlanType(products),
    buy_type: 'direct buy',
    conversion_type: 'purchase',
    payment_type: paymentMethod,
    error_purchase_message: errMsg,
  });

/**
 * @param {string} eventName
 * @param {EventDetails} event
 */
export default (eventName, event: any) => {
  switch (eventName) {
    case ViewContent:
      trackViewContent(event);
      break;

    case ClickCTA:
      trackClickCTA(event);
      break;

    case PurchaseError:
      trakcErrorPurchase(event);
      break;

    default:
      break;
  }
};
