import { configureStore } from '@reduxjs/toolkit';

import { RootState } from 'types/root';
import trackingMiddleware from './middlewares/tracking-middleware';
import rootReducer from './reducer';

/**
 * NOTE: Redux Toolkit comes with default configuration and it's recommended to use this
 * instead of configuring thunk, middleware and createStore.
 * https://redux.js.org/usage/migrating-to-modern-redux
 */

export const setupStore = (preloadedState?: Partial<RootState>) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(trackingMiddleware),
  });

export const store = setupStore();
