/**
 * Layout
 * Please update any global style here!
 */

import { useEffect, useState } from 'react';
import { LaunchScreen } from '@getgo/chameleon-web-react-wrapper';

import { useAppDispatch, useAppSelector } from 'hooks';
import CybersourceFingerprint from 'lib/cybersource-fingerprint';
import { accountKey, language, locale, theme } from 'modules/global-wrapper';
import { paymentMethodsIsLoading, postPaymentMethods } from 'modules/payment-methods';
import {
  postSessionDetails,
  sessionClientURL,
  sessionCountry,
  sessionIsLoading,
  sessionProducts,
} from 'modules/session-details';
import Track, { OnePayLanding, ViewContent } from 'modules/tracking';
import CoreView from './core-view';

const CoreContainer = () => {
  const dispatch = useAppDispatch();

  const [isComponentLoading, setComponentLoading] = useState<boolean>(true);

  const selectedGlobalTheme = useAppSelector(theme);
  const selectedAccountKey = useAppSelector(accountKey);
  const selectedGlobalLanguage = useAppSelector(language);
  const selectedGlobalLocale = useAppSelector(locale);

  const selectedSessionIsLoading = useAppSelector(sessionIsLoading);
  const selectedSessionCountry = useAppSelector(sessionCountry);
  const selectedSessionProducts = useAppSelector(sessionProducts);
  const selectedReferralURL = useAppSelector(sessionClientURL);

  const selectedPaymentsIsLoading = useAppSelector(paymentMethodsIsLoading);

  useEffect(() => {
    if (selectedAccountKey) {
      Track(OnePayLanding, {});
    }
  }, [selectedAccountKey]);

  useEffect(() => {
    if (
      selectedSessionProducts &&
      selectedSessionCountry &&
      selectedGlobalLanguage &&
      selectedGlobalLocale &&
      selectedReferralURL
    ) {
      Track(ViewContent, {
        country: selectedSessionCountry,
        language: selectedGlobalLanguage,
        products: selectedSessionProducts,
        locale: selectedGlobalLocale,
        referrerURL: selectedReferralURL,
      });
    }
  }, [
    selectedGlobalLanguage,
    selectedGlobalLocale,
    selectedReferralURL,
    selectedSessionCountry,
    selectedSessionProducts,
  ]);

  /**
   * Load cybersource fingerprint for the billing country
   */
  useEffect(() => {
    if (selectedSessionCountry) {
      CybersourceFingerprint.load(selectedSessionCountry)
        .then(() => setComponentLoading(false))
        .catch(() => setComponentLoading(false));
    }
  }, [selectedSessionCountry]);

  /**
   * Updating the body style for Dark mode.
   * ChameleonThemeProvider isn't working as expected.
   *  */
  useEffect(() => {
    const { body } = document;
    if (body) {
      body.style.backgroundColor = selectedGlobalTheme === 'dark' ? '#25282d' : '#fff';
      body.style.color = selectedGlobalTheme === 'dark' ? '#fff' : '#25282d';
    }
  }, [selectedGlobalTheme]);

  /**
   * Calling all necessary APIs before mounting the routed component
   */
  useEffect(() => {
    if (selectedAccountKey) {
      dispatch(postSessionDetails());
      dispatch(postPaymentMethods());
    }
  }, [dispatch, selectedAccountKey]);

  const isLoading = isComponentLoading || selectedPaymentsIsLoading || selectedSessionIsLoading;

  return <>{isLoading ? <LaunchScreen /> : <CoreView />}</>;
};

export default CoreContainer;
