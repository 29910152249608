import config from 'config';
import { RootState } from 'types/root';
import { BillingAddress, SessionDetailsState, TransactionProduct } from 'types/session-details';

export const sessionDetailsTree = (state: RootState): SessionDetailsState => state.sessionDetails || {};

export const sessionIsLoading = (state: RootState): boolean => sessionDetailsTree(state).isLoading || false;

export const sessionBillingAddress = (state: RootState): BillingAddress => sessionDetailsTree(state).address || {};

export const sessionEmail = (state: RootState): string => sessionBillingAddress(state).email || '';

export const sessionCountry = (state: RootState): string => sessionBillingAddress(state).country || '';

export const sessionTotalAmount = (state: RootState): number => sessionDetailsTree(state).amount || 0;

export const sessionCurrencyCode = (state: RootState): string => sessionDetailsTree(state).currencyCode || '';

export const sessionQuoteId = (state: RootState): string => sessionDetailsTree(state).quoteId || '';

export const sessionProducts = (state: RootState): TransactionProduct[] => sessionDetailsTree(state).products || [];

export const sessionSuccessRedirectUrl = (state: RootState): string =>
  sessionDetailsTree(state).successRedirectUrl || '';

export const sesssionCancelRedirectUrl = (state: RootState): string =>
  sessionDetailsTree(state).cancelRedirectUrl || '';

export const sessionValidUntil = (state: RootState): string => sessionDetailsTree(state).validUntil || '';

export const isSessionExpired = (state: RootState): boolean => sessionDetailsTree(state).isSessionExpired || false;

export const sessionIntent = (state: RootState): string => sessionDetailsTree(state).intent || '';

export const sessionClientURL = (state: RootState): string => {
  const cancelURL = sesssionCancelRedirectUrl(state);
  if (cancelURL) {
    const url = new URL(sesssionCancelRedirectUrl(state));
    return url.origin;
  }
  return '';
};

export const isEcomClient = (state: RootState): boolean => {
  const clientURL = sessionClientURL(state);
  return config.ECOM_URLS.includes(clientURL);
};
